<template>
    <AppLayout>
        <v-row justify="center"  class="py-5">
            <v-col style="text-align: center">
                 <h2 class="text-h3 font-weight-light">Start your free trial</h2>
            </v-col>
        </v-row>
        <v-row justify="center" class="mb-2">
            <v-col style="text-align: center">
                <p class="text-body-1 font-weight-light">This will be quick. Credit card is <strong>NOT</strong> required.</p>
            </v-col>
        </v-row>
        <!-- <QuickStartSteps :highlight="highlight" :current="step"/> -->

        <!-- authenticated users don't need to opt-in again -->
        <v-row justify="center" class="my-0 pt-0 pb-10" v-if="isViewReady && isAuthenticated && !error && !isRegisterQuickUserPending">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-row justify="center" class="py-5">
                    <SelectAccount :create="{ enable: true, defaultName: webauthzClientName }" :type="accountTypeEnterprise" @selected="selectAccount"/>
                </v-row>
            </v-col>
        </v-row>

        <!-- error message -->
        <v-row justify="center" class="my-0 py-5 pb-10" v-if="error">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <p class="red--text text-center">We cannot process this request.</p>
                <v-alert dense type="error" v-if="!isAuthenticated">
                    <span class="text-body-1">You must be authenticated to continue</span>
                </v-alert>
                <!-- <v-alert dense type="error" v-if="!interactionId">
                    <span class="text-body-1">Bad request</span>
                </v-alert> -->
                <v-alert dense type="error" v-if="isEnterpriseAccountLimit">
                    <span class="text-body-1">Enterprise account limit reached</span>
                </v-alert>
                <p class="text-center"><a href="/contact/?topic=support">Contact support</a></p>
            </v-col>
        </v-row>
    </AppLayout>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import loginshield from '@/client';
// import OptIn from '@/components/Optin.vue';
// import QuickStartSteps from '@/components/QuickStartSteps.vue';
// import Software from '@/views/Software.vue';
import AppLayout from '@/components/AppLayout.vue';
import SelectAccount from '@/components/SelectAccount.vue';
import { isValidName, isValidEmail } from '@/sdk/input';
import { ACCOUNT_TYPE_ENTERPRISE } from '@/constants';

function first(value) {
    if (typeof value === 'string') {
        return value;
    }
    if (typeof value === 'object' && typeof value[0] === 'string') {
        return value[0];
    }
    return null;
}

/*
How this page works:

1. when the user arrives, we check if the user is authenticated or not

2. if the user is not authenticated, we redirect to the quick registration page; from there, the user can either register or login and then return to this page

3. if the user is authenticated, we check if the user already has an enterprise account with a matching realm; if so, that account is automatically selected and displayed, otherwise:

    3a. if the user does NOT have an enterprise account with a matching realm, the user can select an existing enterprise account for the free trial, or choose to create a new enterprise account
    3b. if the user chooses to create a new enterprise account, we will prompt for the company name and then create the account; after creating the new enterprise account, the new account will be automatically selected and the create button will be hidden
    3c. the "continue" button is disabled until an enterprise account is selected

4. when the user taps the "continue" button, if there is no realm id yet we create a new realm in the selected account, then we redirect to the webauthz prompt view with the webauthz parameters, the account id, and the realm id, for the user to confirm the access

5. when the user confirms the access in the webauthz prompt, the server creates a grant token and provides a redirect url (back to the originating application) and we redirect the user there so the application can obtain an access token to manage the realm, upload an icon, and start requesting logins

*/

export default {
    components: {
        // OptIn,
        // QuickStartSteps,
        // Software,
        AppLayout,
        SelectAccount,
    },
    data() {
        return {
            interactionId: null, // the webauthz access request interaction
            webauthzRealm: null, // from webauthz access request
            webauthzScope: null, // from webauthz access request
            webauthzClientName: null, // from webauthz access request
            webauthzClientURI: null, // from webauthz access request
            status: null, // from webauthz interaction, one of 'start' or 'prompt'
            error: false, // true when the webauthz request is invalid
            verificationForm: false, // true when all field inputs are ready to submit
            email: null, // email input from verification form
            name: null, // name input from registration form
            isAgreeToTermsChecked: false, // terms of service checkbox from registration form
            isInteractionReady: false, // true when we load an interaction
            isVerificationPending: false, // email verification was requested but not yet completd by user
            isVerificationReady: false, // email verification email was sent (server returned success status)
            // isViewReady: false, // true after we check session status, see init()
            isVerified: false, // email verification completed (also true when user is authenticated)
            isLoginRequired: false, // true when email is verified and associated to existing account
            // isNewAccount: false, // true when user selects to create new account
            targetAccountId: null, // non-null when user selects an existing account
            isNewRealm: false, // true when user selects an account without a matching realm
            targetRealmId: null, // non-null when user selects an account with a matching realm
            isEnterpriseAccountLimit: false,
            isRegisterQuickUserPending: false, // true when we register the user and add the first trusted gateway public key
            // isContinueReady: false, // this was not working as a computed property
            nameRules: [
                (v) => isValidName(v) || 'What should we call you? Your first name, nickname, or alias',
            ],
            emailRules: [
                (v) => isValidEmail(v) || 'What email address can we use to reach you?',
            ],
            accountTypeEnterprise: ACCOUNT_TYPE_ENTERPRISE, // make this constant available in the template
        };
    },
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady, // true after we check if user is authenticated or not (to avoid content flicker while we check)
            session: (state) => state.session,
            user: (state) => state.user,
            accountMap: (state) => state.accountMap,
            accountId: (state) => state.accountId,
        }),
        ...mapGetters({
            enterpriseAccountList: 'enterpriseAccountList', // sorted enterprise accounts
            nonEnterpriseAccountList: 'nonEnterpriseAccountList', // sorted non-enterprise accounts
            currentAccount: 'account', // currently selected account object
        }),
        accountList() {
            return this.enterpriseAccountList.filter((item) => item.permit && item.permit.includes('edit'));
        },
        accountType() {
            return this.currentAccount ? this.currentAccount.type : '';
        },
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        isVerificationFormComplete() {
            return this.name && isValidName(this.name) && this.email && isValidEmail(this.email) && this.isAgreeToTermsChecked;
        },
        // isContinueReady() {
        //     return (this.targetAccountId /* || this.isNewAccount */) && (this.targetRealmId || this.isNewRealm);
        // },
        isViewReady() {
            return this.isAuthenticatedReady && this.isInteractionReady;
        },
    },
    methods: {
        async loadInteraction(interactionId) {
            try {
                this.$store.commit('loading', { loadInteraction: true });
                const interactionResponse = await this.$store.dispatch('loadInteraction', interactionId);
                console.log(`webauthz-start: interaction ${JSON.stringify(interactionResponse)}`);
                const { type, state } = interactionResponse;
                if (type !== 'webauthz') {
                    console.error(`loadInteraction: invalid interaction type: ${type}`);
                    this.error = true;
                    return;
                }
                this.interactionId = interactionId;
                const { realm, scope, clientName, clientURI, accountId, realmId, status } = state;
                this.webauthzRealm = realm;
                this.webauthzScope = scope;
                this.webauthzClientName = clientName;
                this.webauthzClientURI = clientURI;
                this.targetAccountId = accountId;
                this.targetRealmId = realmId;
                this.status = status;
            } catch (err) {
                console.error(err);
                this.interactionId = null;
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadInteraction: false });
                this.isInteractionReady = true;
            }
        },
        async editWebauthzInteraction({ accountId, realmId }) {
            console.log(`editWebauthzInteraction: interactionId ${this.interactionId}`);

            try {
                this.$store.commit('loading', { editInteraction: true });
                const response = await loginshield.interaction.edit(this.interactionId, {
                    action: 'edit',
                    accountId,
                    realmId,
                });
                return response;
            } catch (err) {
                console.error('editWebauthzInteraction: failed', err);
                return { isEdited: false };
            } finally {
                this.$store.commit('loading', { editInteraction: false });
            }
        },
        async selectAccount(id) {
            console.log(`selectAccount: ${id}`);
            // NOTE: already moved this section to SelectAccount.vue
            // // make sure the selected account is an enterprise account; shouldn't happen since we only display enterprise accounts
            // if (typeof this.accountMap[id] !== 'object' || this.accountMap[id].type !== ACCOUNT_TYPE_ENTERPRISE) {
            //     console.error('selectAccount: must select an enterprise account');
            //     this.targetAccountId = null;
            //     // this.isContinueReady = false;
            //     return;
            // }

            // user selected an account into which to add the free trial
            // this.isNewAccount = false;
            // this.isNewRealm = null;
            // store the selected account id
            this.targetAccountId = id;
            // this.targetRealmId = null;
            // check if a matching realm already exists
            try {
                this.$store.commit('loading', { getByAccountIdRealmURI: true });
                console.log(`selectAccount: search account ${this.targetAccountId} for realm with uri ${this.webauthzClientURI}`);
                const response = await loginshield.realm.getByAccountIdRealmURI(this.targetAccountId, this.webauthzClientURI);
                console.log(`selectAccount: found realm: ${JSON.stringify(response)}`);
                if (typeof response === 'object' && response !== null && typeof response.id === 'string') {
                    console.log(`selectAccount: realm id ${response.id}`);
                    this.isNewRealm = false;
                    this.targetRealmId = response.id;
                } else {
                    this.isNewRealm = true;
                    this.targetRealmId = null;
                }
                // this.isContinueReady = true;
            } catch (err) {
                if (err.response && err.response.status === 401) {
                    console.error('selectAccount: matching realm not found');
                    this.isNewRealm = true;
                    this.targetRealmId = null;
                    // this.isContinueReady = true;
                } else {
                    console.error('selectAccount: find matching realm failed', err);
                    this.error = true;
                    this.isNewRealm = false;
                    this.targetRealmId = null;
                    // this.isContinueReady = false;
                }
            } finally {
                this.$store.commit('loading', { getByAccountIdRealmURI: false });
            }

            // if user selected to create a new realm, do that now and get the realm id
            if (this.isNewRealm) {
                // create new authentication realm, get realm id
                try {
                    this.$store.commit('loading', { createEnterpriseRealm: true });
                    const createRealmResponse = await loginshield.account.createRealm({
                        accountId: this.targetAccountId,
                        label: this.webauthzClientName,
                        uri: this.webauthzClientURI,
                    });
                    // store the selected realm id
                    this.targetRealmId = createRealmResponse.id;
                    // clear the flag so we don't do this twice by accident
                    this.isNewRealm = false;
                } catch (err) {
                    this.error = true;
                    if (err.response && err.response.status) {
                        if (err.response.status === 402) {
                            // payment required: user has too many enterprise accounts
                            this.isEnterpriseAccountLimit = true;
                            // TODO: instead of contacting support, offer to upgrade individual account to "pro" to create more enterprise accounts
                        }
                    }
                    return;
                } finally {
                    this.$store.commit('loading', { createEnterpriseRealm: false });
                }
            }
            // store the selected account id and realm id, so if the user navigates back to this
            // view from the prompt, we can show the selected values instead of making them start over.
            // also, the server validates the user has permission to manage the specified account and
            // realm, and that the realm is associated to the account, so the prompt view will only
            // show validated info
            const { isEdited } = await this.editWebauthzInteraction({
                accountId: this.targetAccountId,
                realmId: this.targetRealmId,
            });
            console.log(`onSubmitAuthenticated: isEdited ${isEdited}`);
            // redirect to webauthz prompt
            this.$router.push({ path: '/webauthz/prompt', query: { i: this.interactionId } });
        },
        // async onCreateAccount() {
        //     console.log('onCreateAccount');
        //     // user selected to create a new enterprise account into which to add the free trial
        //     this.isNewAccount = true;
        //     this.isNewRealm = true;
        //     // clear any previously stored account id and realm id
        //     this.targetAccountId = null;
        //     this.targetRealmId = null;
        //     // select the first non-enterprise account in the navigation menu
        //     if (this.nonEnterpriseAccountList.length > 0) {
        //         await this.$store.dispatch('switchAccount', this.nonEnterpriseAccountList[0].id);
        //     }
        // },
        async onSubmitAuthenticated() {
            // if (!this.targetAccountId && !this.isNewAccount && this.enterpriseAccountList.length > 0 && this.currentAccount && this.currentAccount.type === ACCOUNT_TYPE_ENTERPRISE) {
            // user kept current account without changing selection
            // this.targetAccountId = this.currentAccount.id;
            // }
            // if (!this.targetAccountId && !this.isNewAccount && !this.targetRealmId && !this.isNewRealm && this.accountList.length === 0) {
            // for users who don't have any enterprise accounts, we will create one automatically
            // await this.onCreateAccount();
            // }

            // TODO: move the isNewAccount section into SelectAccount.vue
            // if user selected to create a new account, do that now and get the account id
            // if (this.isNewAccount) {
            //     // create new enterprise account, get account id
            //     try {
            //         this.$store.commit('loading', { createEnterpriseAccount: true });
            //         const createAccountResponse = await loginshield.account.create({
            //             name: this.webauthzClientName,
            //             type: ACCOUNT_TYPE_ENTERPRISE,
            //         });
            //         const newAccountId = createAccountResponse.id;
            //         // reload account list for nav menu
            //         await this.$store.dispatch('loadAccount');
            //         // switch to that account since it was selected for this action (so if user goes to menu, the same account will be selected)
            //         await this.$store.dispatch('switchAccount', newAccountId);
            //         // store the selected account id
            //         this.targetAccountId = newAccountId;
            //         // clear the flag so we don't do this twice by accident
            //         this.isNewAccount = false;
            //     } catch (err) {
            //         console.error('onSubmitAuthenticated: create account error', err);
            //         this.error = true;
            //         if (err.response && err.response.status) {
            //             if (err.response.status === 402) {
            //                 // payment required: user has too many enterprise accounts
            //                 this.isEnterpriseAccountLimit = true;
            //                 // TODO: instead of contacting support, offer to upgrade individual account to "pro" to create more enterprise accounts
            //             }
            //         }
            //         return;
            //     } finally {
            //         this.$store.commit('loading', { createEnterpriseAccount: false });
            //     }
            // }

        },
    },

    watch: {
        // isAuthenticatedReady(value, oldValue) {
        //     console.log('start.vue: isAuthenticatedReady changed from %o to %o', oldValue, value);
        //     // only call init again if ready changed from false to true after mounted()
        //     if (value && !oldValue) {
        //         this.init();
        //     }
        // },
        // '$route.query.step': function onChangeStep() {
        //     this.step = this.$route.query.step;
        // },
        isViewReady(value) {
            if (value === true) {
                if (this.interactionId && this.status === 'start' && !this.isAuthenticated) {
                    // the quick registration page will prompt the user to register or login, then return the authenticated user here
                    this.$router.replace({ path: '/register/quick', query: { i: this.interactionId } });
                    return;
                }
                if (this.interactionId && this.status === 'prompt' && this.isAuthenticated) {
                    // if user ends up at this page but they already completed the 'start' step, return them to the 'prompt' step
                    // this.$router.replace({ path: '/webauthz/prompt', query: { i: this.interactionId } }); // XXX TODO  TEMPORARY DEBUG DISABLE TO TEST SOMETHING ELSE
                    return;
                }
                if (!this.interactionId || !this.isAuthenticated) {
                    this.error = true;
                }
            }
        },
    },

    mounted() {
        const interactionId = first(this.$route.query.i);
        if (interactionId) {
            this.loadInteraction(interactionId);
        } else {
            this.isInteractionReady = true;
        }

        // if (this.isAuthenticatedReady) {
        //     this.init();
        // }
    },
};
</script>
