<template>
    <v-snackbar v-model="display" :timeout="timeout" top :color="snackbarColor" class="mt-0 pt-0">
        <span>{{ attr.message }}</span>
        <template v-slot:action="{ attrs }">
            <v-btn text :class="buttonClass" @click="display = false" v-bind="attrs" v-if="buttonDisplay"><font-awesome-icon :icon="['fas','check']" fixed-width/></v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {

    props: {
        attr: {
            type: Object,
            default() {
                return {
                    message: '',
                    type: '', // 'error', 'warn', 'info', 'success'
                    withButton: true,
                };
            },
        },
        timeout: {
            type: Number,
            default: 2000,
        },
    },

    data() {
        return {
            display: false,
        };
    },

    computed: {
        snackbarColor() {
            switch (this.attr.type) {
            case 'error':
                return 'red';
            case 'warn':
                return 'amber';
            case 'info':
                return 'blue';
            case 'success':
                return 'green';
            default:
                return 'gray';
            }
        },
        buttonClass() {
            switch (this.attr.type) {
            case 'error':
                return 'red white--text';
            case 'warn':
                return 'amber white--text';
            case 'info':
                return 'blue white--text';
            case 'success':
                return 'green white--text';
            default:
                return 'gray white--text';
            }
        },
        buttonDisplay() {
            return this.attr.withButton;
        },
    },

    watch: {
        attr() {
            // automatically display snackbar whenever attributes change
            this.display = true;
        },
    },
};
</script>
