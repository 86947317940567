<template>
    <v-card elevation="4" style="width: 100%; max-width: 800px;">
        <Snackbar :attr="snackbar" />
        <template v-if="accountList.length === 0 && !isCreateEnabled">
            <v-card-text class="text-text-body-1 text-center">
                You don't have any accounts.
            </v-card-text>
        </template>
        <template v-if="accountList.length === 0 && isCreateEnabled && !isCreateAccountActive">
            <!-- TODO:  instead of this text which seems pointless, THIS is where we show the available payment plans and let user select it, so we'll associate it with the account right away when it's created ;  also, if the user arrived with a payment plan preselected (because they started the process from the pricing page) we'll just apply that immediately and don't need to prompt them to select ...  .... which means that whatever redirects the user here needs to specify EITHER:  1) a valid enterprise product plan id, or 2) a valid enterprise product plan *tag* (e.g. "wordpress") so we can look up relevant available plans and display -->
            <!-- if (isViewReady && isAuthenticated && !error && !isRegisterQuickUserPending && accountList.length === 0 && productTag === 'wordpress') { window.location.href = '/connect/wordpress/?from=register&i=<interactionId>#pricing' } -->

            <v-card-text class="text-text-body-1 text-center">When you tap continue below, we'll create an enterprise account for your free trial. You'll be able to customize it later.</v-card-text>
            <v-row justify="center" class="mt-2 mb-4">
                <v-btn elevation="4" class="blue white--text" @click="createFirstAccountClicked">Continue</v-btn>
            </v-row>
        </template>
        <!-- if the user has one account of the requested type, we will select it automatically as the default; we will also make a "+" new account button available; if the user wants to upgrade to a "pro" individual account instead of a free individual account, they can manage multiple enterprise accounts -->
        <template v-if="accountList.length > 0 && !isCreateFirstAccount && !isCreateAccountActive">
            <v-list-item>
                <v-list-item-title class="text-h6">Select an account</v-list-item-title>
                <v-list-item-icon v-if="isCreateEnabled">
                    <v-btn color="blue" icon @click="createAccountClicked()">
                        <font-awesome-icon fixed-width size="2x" :icon="['fas', 'plus']"></font-awesome-icon>
                    </v-btn>
                </v-list-item-icon>
            </v-list-item>
            <!-- <v-card-text class="text-h6 text-center"></v-card-text> -->
            <v-list class="ma-0 pa-0">
                <template v-for="(account, idx) in accountList">
                    <v-divider v-bind:key="'divider_'+idx"></v-divider>
                    <v-list-item v-bind:key="'item_'+idx" @click="selectAccount(account.id)" :class="{'lighten-5':true, 'blue':(account.id === currentAccount.id)}" style="padding-left: 8px;">
                        <v-list-item-avatar tile style="margin-right: 8px;">
                            <Avatar :attr="account" :size="32"/>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{ account.name }}</v-list-item-title>
                            <v-list-item-subtitle class="text-overline" style="margin-left: 2px">{{ account.type }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </template>
        <template v-if="isCreateAccountActive">
            <v-form @submit="validateCreateAccountForm" onSubmit="return false;" @keyup.enter.native="validateCreateAccountForm" class="px-5 py-5">
                <!-- <p>We need this information to get started:</p> -->
                <v-text-field
                ref="nameInput"
                v-model="editableAccountName"
                label="Account name"
                required
                outlined
                hint="What should we call this account?"
                type="text">
                    <!-- <template v-slot:prepend>
                        <font-awesome-icon icon="user" fixed-width class="mt-1"/>
                    </template> -->
                </v-text-field>
                <p class="red--text" v-if="isExistingName">You have an account with a similar name</p>
                <v-row justify="center">
                    <v-card-actions>
                        <v-btn elevation="4" @click="createAccount()" :disabled="!isCreateAccountFormComplete" class="blue white--text">
                            <span>Create</span>
                        </v-btn>
                        <v-btn @click="isCreateAccountActive = false" text class="grey--text">
                            <span>Cancel</span>
                        </v-btn>
                    </v-card-actions>
                </v-row>
            </v-form>
        </template>
    </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { isValidName, compact } from '@/sdk/input';
import Avatar from '@/components/Avatar.vue';
import Snackbar from '@/components/Snackbar.vue';
import loginshield from '@/client';

export default {
    components: {
        Avatar,
        Snackbar,
    },
    props: {
        /**
         * Enable the create account buttons
         */
        create: {
            type: Object,
            default() {
                return {
                    enable: false, // true if create button should be enabled
                    defaultName: null, // set if there is a sensible default for the new account name; user will have an opportunity to edit
                };
            },
        },
        /**
         * Limit the account types (e.g. 'enterprise' to select an enterprise account).
         * Default is to show all accounts.
         */
        type: {
            type: String,
            default: null,
        },
        /**
         * By default the component will switch the current account to the selected account.
         * Specify `noswitch` to prevent that behavior.
         */
        noswitch: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            snackbar: {},
            editableAccountName: null,
            isCreateAccountActive: false,
            isCreateFirstAccount: false,
            selectedAccountId: null, // account id selected by user
            isCreating: false,
        };
    },
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady, // true after we check if user is authenticated or not (to avoid content flicker while we check)
            session: (state) => state.session,
            user: (state) => state.user,
            accountMap: (state) => state.accountMap,
            accountId: (state) => state.accountId,
        }),
        ...mapGetters({
            enterpriseAccountList: 'enterpriseAccountList', // sorted enterprise accounts
            nonEnterpriseAccountList: 'nonEnterpriseAccountList', // sorted non-enterprise accounts
            currentAccount: 'account', // currently selected account object
        }),
        accountList() {
            return this.enterpriseAccountList.filter((item) => item.permit && item.permit.includes('edit'));
        },
        // accountType() {
        //     return this.currentAccount ? this.currentAccount.type : '';
        // },
        isCreateEnabled() {
            return typeof this.create === 'object' && this.create !== null && this.create.enable;
        },
        isAutoSwitchEnabled() {
            return !this.noswitch;
        },
        isExistingName() {
            return !this.isCreating && this.accountList.find((item) => compact(item.name) === compact(this.editableAccountName));
        },
        isCreateAccountFormComplete() {
            return this.editableAccountName && isValidName(this.editableAccountName) && !this.isExistingName;
        },
    },
    methods: {
        prepareCreateAccountForm() {
            const compactAccountNameList = this.accountList.map((item) => compact(item.name));
            const isNameInAccountList = (name) => {
                const compactName = compact(name);
                return compactAccountNameList.find((item) => item === compactName);
            };

            let i = 1;
            let prefix;
            let name;
            if (typeof this.create.defaultName === 'string' && this.create.defaultName.length > 0) {
                prefix = this.create.defaultName;
                name = this.create.defaultName;
            } else {
                prefix = 'Account';
                name = `${prefix} ${i}`;
            }
            while (isNameInAccountList(name)) {
                i += 1;
                name = `${prefix} ${i}`;
            }
            this.editableAccountName = name;

            // show the form
            this.isCreating = false;
            this.isCreateAccountActive = true;
            this.$nextTick(() => { this.$refs.nameInput.focus(); });
        },
        async createAccount() {
            // create new enterprise account, get account id
            try {
                if (this.isExistingName) {
                    this.snackbar = { type: 'error', message: 'You have an account with a similar name' };
                    this.$refs.nameInput.focus();
                    return;
                }

                // mark the account name as being created, so that after we reload the account list,
                // we won't show an error message that an account with the same name exists
                this.isCreating = true;

                this.$store.commit('loading', { createEnterpriseAccount: true });
                const createAccountResponse = await loginshield.account.create({
                    name: this.editableAccountName,
                    type: this.type,
                });
                const newAccountId = createAccountResponse.id;

                // reload account list for nav menu
                await this.$store.dispatch('loadAccount');

                if (this.isAutoSwitchEnabled) {
                    // switch to that account since it was selected for this action (so if user goes to menu, the same account will be selected)
                    await this.$store.dispatch('switchAccount', newAccountId);
                }

                await this.selectAccount(newAccountId);
            } catch (err) {
                console.error('createAccount: create account error', err);
                this.error = true;
                if (err.response && err.response.status) {
                    if (err.response.status === 402) {
                        // payment required: user has too many enterprise accounts
                        this.isEnterpriseAccountLimit = true;
                        // TODO: instead of contacting support, offer to upgrade individual account to "pro" to create more enterprise accounts
                    }
                }
                return;
            } finally {
                this.$store.commit('loading', { createEnterpriseAccount: false });
                this.isCreating = false;
                this.isCreateAccountActive = false;
            }
        },
        async createAccountClicked() {
            console.log('createAccount');
            // user selected to create a new account
            this.prepareCreateAccountForm();
        },
        async createFirstAccountClicked() {
            this.isCreateFirstAccount = true; // prevent the account selection list from flickering into view when we create the user's first account
            this.prepareCreateAccountForm();
        },
        async selectAccount(id) {
            // if an account type was specified, make sure the selected account is of that type
            if (typeof this.type === 'string' && this.type.length > 0) {
                if (typeof this.accountMap[id] !== 'object' || this.accountMap[id].type !== this.type) {
                    console.error(`selectAccount: selected account type ${this.accountMap[id].type} does not match required type ${this.type}`);
                    this.selectedAccountId = null;
                    return;
                }
            }
            this.selectedAccountId = id;
            this.$emit('selected', id);
            this.$nextTick(() => { this.isCreateFirstAccount = false; });
        },
        validateCreateAccountForm() {
            if (this.isCreateAccountFormComplete) {
                this.createAccount();
            }
        },
    },
    mounted() {
        if (this.accountList.length === 0 && this.isCreateEnabled) {
            this.prepareCreateAccountForm();
        }
    },
};
</script>
